import { Bookmark, PieChart, Radio, Server } from "react-feather";

const asideMenus = [
    {
        id: 1,
        label: "Web Extraction",
        url: "/",
        submenu: [
            {
                id: 1,
                label: "Dashboard",
                url: "/dashboard",
                Icon: PieChart,
            },
            {
                id: 1,
                label: "URL Aggregator",
                url: "/",
                Icon: Bookmark,
            },
            {
                id: 3,
                label: "Web Extracting Process",
                url: "/scraping-process",
                Icon: Server,
            },
            {
                id: 2,
                label: "Staff Data",
                url: "/staff-data",
                Icon: Radio,
            },
        ],
    },
];

export default asideMenus;
