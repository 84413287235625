import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Preloader from "./components/preloader";

// Secure Page
const Signin = lazy(() => import("./pages/secure/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const ProfileView = lazy(() => import("./pages/profile-view"));
const Connections = lazy(() => import("./pages/connections"));
const Groups = lazy(() => import("./pages/groups"));
const Events = lazy(() => import("./pages/events"));
const Timeline = lazy(() => import("./pages/timeline"));
const Pricing = lazy(() => import("./pages/pricing"));
const HelpCenter = lazy(() => import("./pages/help-center"));
const Invoice = lazy(() => import("./pages/invoice"));
const Calendar = lazy(() => import("./pages/apps/calendar"));
const Chat = lazy(() => import("./pages/apps/chat"));
const Contacts = lazy(() => import("./pages/apps/contacts"));
const FileManager = lazy(() => import("./pages/apps/file-manager"));
const Mail = lazy(() => import("./pages/apps/mail"));

const StaffUrlPage = lazy(() => import("./pages/core/staff-url"));
const ScrapingPage = lazy(() => import("./pages/core/scraping-process"));
const StaffDataPage = lazy(() => import("./pages/core/staff-data"));
const Dashboard = lazy(() => import("./pages/core/dashboard"));
const App = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route path="/" element={<StaffUrlPage />} />
                        <Route
                            path="/scraping-process"
                            element={<ScrapingPage />}
                        />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/staff-data" element={<StaffDataPage />} />
                        {/* Secure Routes */}
                        <Route path="secure/signin" element={<Signin />} />
                        {/* Authentication Routes */}
                        <Route path="/signup" element={<SignUp />} />
                        <Route
                            path="/verify-account"
                            element={<VerifyAccount />}
                        />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPassword />}
                        />

                        {/* Error Routes */}
                        <Route path="/error-500" element={<Error500 />} />
                        <Route path="/error-503" element={<Error503 />} />
                        <Route path="/error-505" element={<Error505 />} />

                        {/* User Routes */}
                        <Route path="/profile-view" element={<ProfileView />} />
                        <Route path="/connections" element={<Connections />} />
                        <Route path="/groups" element={<Groups />} />
                        <Route path="/events" element={<Events />} />

                        {/* Other Routes */}
                        <Route path="/timeline" element={<Timeline />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/help-center" element={<HelpCenter />} />
                        <Route path="/invoice" element={<Invoice />} />

                        {/* Apps Routes */}
                        <Route path="/apps/calendar" element={<Calendar />} />
                        <Route path="/apps/chat" element={<Chat />} />
                        <Route path="/apps/contacts" element={<Contacts />} />
                        <Route
                            path="/apps/file-manager"
                            element={<FileManager />}
                        />
                        <Route path="/apps/mail" element={<Mail />} />

                        {/* 404 Page Route */}
                        <Route path="*" element={<ErrorNotFound />} />
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
